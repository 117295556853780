import ByteReader from "./byte-reader.ts";
import ByteWriter from "./byte-writer.ts";

export enum OutgoingPayloadType {
  Ping = 0,
  LogIn = 1,
  LogOut = 2,
  CreatePrivateGame = 3,
  JoinPrivateGame = 4,
  LeavePrivateGame = 5,
  StartPrivateGame = 6,
  RevealTile = 7,
  FlagTile = 8,
}

export type OutgoingPayload =
  | { type: OutgoingPayloadType.Ping }
  | { type: OutgoingPayloadType.LogIn; username: string }
  | { type: OutgoingPayloadType.LogOut }
  | { type: OutgoingPayloadType.CreatePrivateGame }
  | { type: OutgoingPayloadType.JoinPrivateGame; joinCode: string }
  | { type: OutgoingPayloadType.LeavePrivateGame }
  | { type: OutgoingPayloadType.StartPrivateGame }
  | { type: OutgoingPayloadType.RevealTile; index: number }
  | { type: OutgoingPayloadType.FlagTile; index: number };

export class OutgoingPayloadSerializer {
  public static toBytes(payload: OutgoingPayload): Uint8Array {
    const byteWriter: ByteWriter = new ByteWriter();
    switch (payload.type) {
      case OutgoingPayloadType.Ping: {
        byteWriter.writeU8(payload.type);
        break;
      }
      case OutgoingPayloadType.LogIn: {
        byteWriter.writeU8(payload.type);
        byteWriter.writeString(payload.username);
        break;
      }
      case OutgoingPayloadType.LogOut: {
        byteWriter.writeU8(payload.type);
        break;
      }
      case OutgoingPayloadType.CreatePrivateGame: {
        byteWriter.writeU8(payload.type);
        break;
      }
      case OutgoingPayloadType.JoinPrivateGame: {
        byteWriter.writeU8(payload.type);
        byteWriter.writeString(payload.joinCode);
        break;
      }
      case OutgoingPayloadType.LeavePrivateGame: {
        byteWriter.writeU8(payload.type);
        break;
      }
      case OutgoingPayloadType.StartPrivateGame: {
        byteWriter.writeU8(payload.type);
        break;
      }
      case OutgoingPayloadType.RevealTile: {
        byteWriter.writeU8(payload.type);
        byteWriter.writeU32(payload.index);
        break;
      }
      case OutgoingPayloadType.FlagTile: {
        byteWriter.writeU8(payload.type);
        byteWriter.writeU32(payload.index);
        break;
      }
    }
    return byteWriter.getBytes();
  }

  public static fromBytes(bytes: Uint8Array): OutgoingPayload {
    const byteReader: ByteReader = new ByteReader(bytes);
    if (byteReader.isEmpty()) {
      throw new Error("OutgoingPayload: empty byte array");
    }

    let result: OutgoingPayload;
    const payloadType: number = byteReader.readU8();
    switch (payloadType) {
      case OutgoingPayloadType.Ping: {
        result = { type: payloadType };
        break;
      }
      case OutgoingPayloadType.LogIn: {
        result = { type: payloadType, username: byteReader.readString() };
        break;
      }
      case OutgoingPayloadType.LogOut: {
        result = { type: payloadType };
        break;
      }
      case OutgoingPayloadType.CreatePrivateGame: {
        result = { type: payloadType };
        break;
      }
      case OutgoingPayloadType.JoinPrivateGame: {
        result = { type: payloadType, joinCode: byteReader.readString() };
        break;
      }
      case OutgoingPayloadType.LeavePrivateGame: {
        result = { type: payloadType };
        break;
      }
      case OutgoingPayloadType.StartPrivateGame: {
        result = { type: payloadType };
        break;
      }
      case OutgoingPayloadType.RevealTile: {
        result = { type: payloadType, index: byteReader.readU32() };
        break;
      }
      case OutgoingPayloadType.FlagTile: {
        result = { type: payloadType, index: byteReader.readU32() };
        break;
      }

      default: {
        throw new Error(`OutgoingPayload: unknown message type: '${payloadType}'`);
      }
    }

    // verify that the entire byte array was consumed
    if (!byteReader.isEmpty()) {
      throw new Error("OutgoingPayload: unexpected bytes");
    }

    return result;
  }
}
