import { AccountPayload, PlayerOwnedTileUpdates, WinCondition } from "./codec/incoming-payload.ts";

export class AppState {
  public userState: UserState;
  public gameState: GameState;

  constructor() {
    this.userState = new UserState();
    this.gameState = new GameState();
  }
}

export class UserState {
  private _accountId: string | null;
  private _username: string | null;

  constructor() {
    this._accountId = null;
    this._username = null;
  }

  public get accountId(): string | null {
    return this._accountId;
  }
  public get username(): string | null {
    return this._username;
  }

  public logIn(accountId: string, username: string): void {
    this._accountId = accountId;
    this._username = username;
  }

  public logOut(): void {
    this._accountId = null;
    this._username = null;
  }
}

export enum GameStatus {
  LOBBY,
  IN_PROGRESS,
  FINISHED,
}

export class GameState {
  private _gameId: string | null;
  private _joinCode: string | null;
  private _state: GameStatus | null;
  private _lobby: AccountPayload[] | null;
  private _host: string | null;
  private _width: number | null;
  private _height: number | null;
  private _currentTimeLeftSeconds: number | null;
  private _tileStates: Uint32Array | null;
  private _winner: number | null;
  private _winCondition: WinCondition | null;

  constructor() {
    this._gameId = null;
    this._joinCode = null;
    this._state = null;
    this._lobby = null;
    this._host = null;
    this._width = null;
    this._height = null;
    this._currentTimeLeftSeconds = null;
    this._tileStates = null;
    this._winner = null;
    this._winCondition = null;
  }

  public clear(): void {
    this._gameId = null;
    this._joinCode = null;
    this._state = null;
    this._lobby = null;
    this._host = null;
    this._width = null;
    this._height = null;
    this._currentTimeLeftSeconds = null;
    this._tileStates = null;
    this._winner = null;
    this._winCondition = null;
  }

  public get gameId(): string | null {
    return this._gameId;
  }
  public get joinCode(): string | null {
    return this._joinCode;
  }
  public get state(): GameStatus | null {
    return this._state;
  }
  public get lobby(): AccountPayload[] | null {
    return this._lobby;
  }
  public get host(): string | null {
    return this._host;
  }
  public get boardWidth(): number | null {
    return this._width;
  }
  public get boardHeight(): number | null {
    return this._height;
  }
  public get currentTimeLeftSeconds(): number | null {
    return this._currentTimeLeftSeconds;
  }
  public get tileStates(): Uint32Array | null {
    return this._tileStates;
  }
  public get winner(): number | null {
    return this._winner;
  }
  public get winCondition(): WinCondition | null {
    return this._winCondition;
  }

  public createdPrivateGame(gameId: string, joinCode: string, hostAccountId: string, hostUsername: string): void {
    this._gameId = gameId;
    this._joinCode = joinCode;
    this._state = GameStatus.LOBBY;

    this._lobby = [{ accountId: hostAccountId, username: hostUsername }];
    this._host = hostAccountId;
  }

  public joinedPrivateGame(gameId: string, joinCode: string, lobby: AccountPayload[]): void {
    this._gameId = gameId;
    this._joinCode = joinCode;
    this._state = GameStatus.LOBBY;

    this._lobby = lobby;

    if (lobby.length === 0) {
      throw new Error("GameState: lobby is empty (we're assuming that the first player in the lobby is the host)");
    }
    this._host = lobby[0].accountId;
  }

  public playerJoinedPrivateGame(gameId: string, accountId: string, username: string): void {
    if (this._gameId === null || this._state === null || this._lobby === null) {
      throw new Error("GameState: game is null");
    }
    if (this._state !== GameStatus.LOBBY) {
      throw new Error(`GameState: game is not in lobby state: '${this._state}'`);
    }
    if (this._gameId !== gameId) {
      throw new Error(`GameState: game id mismatch - expected '${this._gameId}', found '${gameId}'`);
    }

    // add player to lobby
    this._lobby.push({ accountId, username });
  }

  /**
   * Returns true if the lobby is now empty.
   */
  public playerLeftPrivateGame(gameId: string, accountId: string): boolean {
    if (this._gameId === null || this._state === null || this._lobby === null) {
      throw new Error("GameState: game is null");
    }
    if (this._gameId !== gameId) {
      throw new Error(`GameState: game id mismatch - expected '${this._gameId}', found '${gameId}'`);
    }

    // a player can leave the lobby even if the game is in progress
    // no need to check if 'state === GameStatus.LOBBY'

    switch (this._state) {
      case GameStatus.LOBBY: {
        /// remove player from lobby
        this._lobby = this._lobby.filter((player) => player.accountId !== accountId);

        // if the player that left was the host, assign a new host
        if (this._lobby.length > 0) {
          // (we're assuming that the first player in the lobby is the host)
          this._host = this._lobby[0].accountId;
          return false;
        } else {
          this._host = null;
          return true;
        }
      }
      case GameStatus.IN_PROGRESS: {
        // TODO - handle player leaving in-progress game
        console.warn("GameState: playerLeftPrivateGame called while game is in progress");
        return false;
      }
      case GameStatus.FINISHED: {
        // TODO - handle player leaving finished game
        console.warn("GameState: playerLeftPrivateGame called while game is finished");
        return false;
      }
      default:
        throw new Error(`GameState: unexpected state: '${this._state}'`);
    }
  }

  public startedPrivateGame(gameId: string, width: number, height: number, maxDurationSeconds: number): void {
    if (this._gameId === null || this._state === null) {
      throw new Error("GameState: game is null");
    }
    if (this._state !== GameStatus.LOBBY) {
      throw new Error(`GameState: game is not in lobby state: '${this._state}'`);
    }
    if (this._gameId !== gameId) {
      throw new Error(`GameState: game id mismatch - expected '${this._gameId}', found '${gameId}'`);
    }

    // mark game in-progress
    this._state = GameStatus.IN_PROGRESS;

    // set board dimensions
    this._width = width;
    this._height = height;

    // set current time left
    this._currentTimeLeftSeconds = maxDurationSeconds - 1;

    // initialize tile states
    // (Chose u32 instead of u8 because buffers need to be aligned to 4 bytes. I could've padded the array and done bitwise ops in the shader, but this is easier for now.)
    // TODO - refactor to use u8 instead of u32
    // (Fill with '9' to represent 'hidden' tiles.)
    this._tileStates = new Uint32Array(this._width * this._height).fill(9);
  }

  public countDownCurrentTimeLeft(): void {
    if (this._currentTimeLeftSeconds === null) {
      throw new Error("GameState: current time left is null");
    }
    if (this._currentTimeLeftSeconds <= 0) {
      throw new Error("GameState: current time left is already 0");
    }

    this._currentTimeLeftSeconds -= 1;
  }

  public gameStateUpdate(
    gameId: string,
    playerOwnedTileUpdates: PlayerOwnedTileUpdates[],
    deadPlayers: number[],
  ): void {
    if (this._gameId === null || this._state === null || this._tileStates === null) {
      throw new Error("GameState: game is null");
    }
    if (this._state !== GameStatus.IN_PROGRESS) {
      throw new Error(`GameState: game is not in 'IN_PROGRESS' state: '${this._state}'`);
    }
    if (this._gameId !== gameId) {
      throw new Error(`GameState: game id mismatch - expected '${this._gameId}', found '${gameId}'`);
    }

    // bulk update tile states
    for (const playerOwnedTileUpdate of playerOwnedTileUpdates) {
      // TODO - track which player owns which tiles
      for (const tileUpdate of playerOwnedTileUpdate.tileUpdates) {
        this._tileStates[tileUpdate.index] = tileUpdate.newType;
      }
    }

    // TODO - display alive/dead users to player
    // TODO - handle game over for current player
    for (const deadPlayer of deadPlayers) {
      console.log(`Player '${deadPlayer}' is dead.`);
    }
  }

  public getGameOver(gameId: string, winner: number, winCondition: WinCondition): void {
    if (this._gameId === null || this._state === null) {
      throw new Error("GameState: game is null");
    }
    if (this._state !== GameStatus.IN_PROGRESS) {
      throw new Error(`GameState: game is not in 'IN_PROGRESS' state: '${this._state}'`);
    }
    if (this._gameId !== gameId) {
      throw new Error(`GameState: game id mismatch - expected '${this._gameId}', found '${gameId}'`);
    }

    this._state = GameStatus.FINISHED;
    this._winner = winner;
    this._winCondition = winCondition;
  }
}
