export default class Renderer {
  // webgpu
  private canvas: HTMLCanvasElement | null = null;
  private device: GPUDevice | null = null;
  private context: GPUCanvasContext | null = null;
  private format: GPUTextureFormat | null = null;
  private pipeline: GPURenderPipeline | null = null;
  private vertexBuffer: GPUBuffer | null = null;
  private instanceBuffer: GPUBuffer | null = null;
  private bindGroup: GPUBindGroup | null = null;
  private uniformBuffer: GPUBuffer | null = null;
  private tileTexture: GPUTexture | null = null;

  // requestAnimationFrame
  private drawAnimationFrameId: number | null = null;
  private movementAnimationFrameId: number | null = null;

  // vec2 gridSize, vec2 worldSize, vec2 cameraPos, vec2 canvasSize float tileSize
  // (2 * 4) + (2 * 4) + (2 * 4) + (2 * 4) + 4 + 4 = 40 bytes
  private readonly UNIFORM_BUFFER_SIZE: number = 40;

  // grid
  private readonly GRID_TILES_WIDTH: number;
  private readonly GRID_TILES_HEIGHT: number;

  // tile size
  private tileSize: number = 64;
  private readonly MIN_TILE_SIZE = 16;
  private readonly MAX_TILE_SIZE = 64;
  private readonly ZOOM_SPEED = 8;

  // camera
  private cameraWorldX: number = 0;
  private cameraWorldY: number = 0;
  private readonly MOVE_SPEED = 10;

  // callbacks
  public callbackRevealTile: ((tileIndex: number) => void) | null = null;
  public callbackFlagTile: ((tileIndex: number) => void) | null = null;

  constructor(parentElement: HTMLElement, gridWidth: number, gridHeight: number, tileStates: Uint32Array) {
    this.GRID_TILES_WIDTH = gridWidth;
    this.GRID_TILES_HEIGHT = gridHeight;

    this.init(parentElement, tileStates).then(() => {});
  }

  public destroy(): void {
    // stop requestAnimationFrame
    if (this.drawAnimationFrameId !== null) {
      cancelAnimationFrame(this.drawAnimationFrameId);
    }
    if (this.movementAnimationFrameId !== null) {
      cancelAnimationFrame(this.movementAnimationFrameId);
    }

    // TODO - remove event listeners
    // TODO - don't trust this claude code \/
    // if (this.canvas) {
    //   this.canvas.removeEventListener("mousedown", this.handleMouseDown);
    //   this.canvas.removeEventListener("contextmenu", this.handleContextMenu);
    // }
    // window.removeEventListener("resize", this.handleResize);
    // window.removeEventListener("wheel", this.handleWheel);
    // window.removeEventListener("keydown", this.handleKeyDown);
    // window.removeEventListener("keyup", this.handleKeyUp);

    // remove the canvas from the DOM
    if (this.canvas !== null) {
      this.canvas.remove();
    }

    // destroy all WebGPU resources
    if (this.vertexBuffer !== null) {
      this.vertexBuffer?.destroy();
    }
    if (this.instanceBuffer !== null) {
      this.instanceBuffer?.destroy();
    }
    if (this.uniformBuffer !== null) {
      this.uniformBuffer?.destroy();
    }
    if (this.tileTexture !== null) {
      this.tileTexture.destroy();
    }
    if (this.device) {
      // (this will implicitly destroy all other GPU resources)
      this.device.destroy();
    }

    // clear references
    this.canvas = null;
    this.device = null;
    this.context = null;
    this.format = null;
    this.pipeline = null;
    this.vertexBuffer = null;
    this.instanceBuffer = null;
    this.bindGroup = null;
    this.uniformBuffer = null;
    this.tileTexture = null;
  }

  private async init(parentElement: HTMLElement, tileStates: Uint32Array): Promise<void> {
    this.createCanvas(parentElement);

    await this.initWebGPU(tileStates);

    // now that we have a context/device/canvas, we can initialize the size/DPI of the canvas
    this.handleResize();

    // listeners
    this.initMovementListeners();
    this.initMouseWheelListener();
    this.initMouseClickListener();

    // start render loop
    this.updateUniformBuffer(); // have to call updateUniformBuffer() once before the render loop starts
    this.drawAnimationFrameId = requestAnimationFrame(this.draw.bind(this));
  }

  private createCanvas(parentElement: HTMLElement): void {
    this.canvas = document.createElement("canvas");
    globalThis.addEventListener("resize", () => {
      this.handleResize();
    });
    parentElement.appendChild(this.canvas);

    // set camera position to center of grid
    // TODO - not sure if this is working correctly (should I offset in shader? that might mess up mouse clicking)
    this.cameraWorldX = ((this.GRID_TILES_WIDTH * this.tileSize) / 2) - (this.canvas.width / 2);
    this.cameraWorldY = ((this.GRID_TILES_HEIGHT * this.tileSize) / 2) - (this.canvas.height / 2);
  }

  private handleResize(): void {
    if (!navigator.gpu) {
      throw new Error("WebGPU not supported on this browser.");
    }
    if (!this.context || !this.device || !this.format) {
      throw new Error("WebGPU not initialized.");
    }
    if (!this.canvas) {
      throw new Error("Canvas not initialized.");
    }

    // set DPI (dots per inch)
    const dpi: number = globalThis.devicePixelRatio || 1;
    this.canvas.width = Math.floor(globalThis.innerWidth * dpi);
    this.canvas.height = Math.floor(globalThis.innerHeight * dpi);
    this.clampCamera();

    this.context.configure({
      device: this.device,
      format: this.format,
      width: this.canvas.width,
      height: this.canvas.height,
    });
    this.updateUniformBuffer();
  }

  private async initWebGPU(tileStates: Uint32Array): Promise<void> {
    if (!navigator.gpu) {
      throw new Error("WebGPU not supported on this browser.");
    }
    if (!this.canvas) {
      throw new Error("Canvas not initialized.");
    }

    // init adapter
    const adapter: GPUAdapter | null = await navigator.gpu.requestAdapter();
    if (!adapter) {
      throw new Error("No appropriate GPUAdapter found.");
    }

    // init device
    const gpuDescriptor: GPUDeviceDescriptor = {
      requiredFeatures: ["texture-compression-bc"],
      requiredLimits: {
        maxStorageBufferBindingSize: 1024 * 1024 * 1024, // 1GB
      },
    };
    this.device = await adapter.requestDevice(gpuDescriptor);
    this.device.lost.then((info: GPUDeviceLostInfo) => {
      switch (info.reason) {
        case "destroyed":
          console.debug("WebGPU device destroyed:", info);
          break;
        default:
          console.error("WebGPU device lost:", info);
          // TODO - handle device loss (reinitialize or show error or something)
          break;
      }
    });

    // init GPU canvas context
    const gpuCanvasContext: RenderingContext | null = this.canvas.getContext("webgpu");
    if (gpuCanvasContext === null) {
      throw Error(
        "Failed to get GPU Canvas Context. WebGPU not supported on this browser. Please try Google Chrome!",
      );
    }
    this.context = gpuCanvasContext as GPUCanvasContext;

    // (storing this because it's used frequently)
    this.format = navigator.gpu.getPreferredCanvasFormat();
    if (this.format === undefined || this.format === null) {
      throw new Error("Failed to get preferred canvas format.");
    }

    // create pipeline
    const shaderModule: GPUShaderModule = this.device.createShaderModule({
      code: this.getShaderCode(),
    });
    const bindGroupLayout = this.device.createBindGroupLayout({
      entries: [
        {
          binding: 0,
          visibility: GPUShaderStage.VERTEX | GPUShaderStage.FRAGMENT,
          buffer: { type: "uniform" },
        },
        {
          binding: 1,
          visibility: GPUShaderStage.VERTEX | GPUShaderStage.FRAGMENT,
          buffer: { type: "read-only-storage" },
        },
        {
          binding: 2,
          visibility: GPUShaderStage.FRAGMENT,
          sampler: { type: "filtering" },
        },
        {
          binding: 3,
          visibility: GPUShaderStage.FRAGMENT,
          texture: { sampleType: "float" },
        },
      ],
    });
    const pipelineLayout = this.device.createPipelineLayout({
      bindGroupLayouts: [bindGroupLayout],
    });
    this.pipeline = await this.device.createRenderPipelineAsync({
      layout: pipelineLayout,
      vertex: {
        module: shaderModule,
        entryPoint: "vertexShader",
        buffers: [{
          arrayStride: 8, // how many bytes per vertex
          attributes: [{
            shaderLocation: 0,
            offset: 0,
            format: "float32x2",
          }],
        }],
      },
      fragment: {
        module: shaderModule,
        entryPoint: "fragmentShader",
        targets: [
          {
            format: this.format,
          },
        ],
      },
      primitive: {
        topology: "triangle-list",
      },
    });

    // init vertex buffer (single square)
    // deno-fmt-ignore
    const vertexData = new Float32Array([
            0, 0,
            1, 0,
            1, 1,
            0, 0,
            1, 1,
            0, 1,
        ]);
    this.vertexBuffer = this.device.createBuffer({
      size: vertexData.byteLength,
      usage: GPUBufferUsage.VERTEX | GPUBufferUsage.COPY_DST,
    });
    this.device.queue.writeBuffer(this.vertexBuffer, 0, vertexData);

    // init instance buffer (tile states)
    this.instanceBuffer = this.device.createBuffer({
      size: tileStates.byteLength,
      usage: GPUBufferUsage.STORAGE | GPUBufferUsage.COPY_DST,
    });
    this.device.queue.writeBuffer(this.instanceBuffer, 0, tileStates);

    // init uniform buffer
    this.uniformBuffer = this.device.createBuffer({
      size: this.UNIFORM_BUFFER_SIZE,
      usage: GPUBufferUsage.UNIFORM | GPUBufferUsage.COPY_DST,
    });
    this.updateUniformBuffer();

    // init tileset texture
    this.tileTexture = await this.loadTexture("static/image/assets/tilesheet.png");
    const sampler: GPUSampler = this.device.createSampler({
      magFilter: "nearest",
      minFilter: "nearest",
    });

    // init bind group
    this.bindGroup = this.device.createBindGroup({
      layout: bindGroupLayout,
      entries: [
        {
          binding: 0,
          resource: { buffer: this.uniformBuffer },
        },
        {
          binding: 1,
          resource: { buffer: this.instanceBuffer },
        },
        {
          binding: 2,
          resource: sampler,
        },
        {
          binding: 3,
          resource: this.tileTexture.createView(),
        },
      ],
    });
  }

  private getShaderCode(): string {
    return `
    struct Uniforms {
      gridSize: vec2f,
      worldSize: vec2f,
      cameraPos: vec2f,
      screenSize: vec2f,
      tileSize: f32,
      padding: f32,
    };

    @group(0) @binding(0) var<uniform> uniforms: Uniforms;
    @group(0) @binding(1) var<storage, read> tileStates: array<u32>;
    @group(0) @binding(2) var tileSampler: sampler;
    @group(0) @binding(3) var tileTexture: texture_2d<f32>;

    struct VertexOutput {
      @builtin(position) position: vec4f,
      @location(0) vertexPosition: vec2f,
      @location(1) @interpolate(flat) tileState: u32,
    };

    @vertex
    fn vertexShader(
      @location(0) vertexPosition: vec2f,
      @builtin(instance_index) instanceIndex: u32
    ) -> VertexOutput {
      let gridPosition = vec2f(
        f32(instanceIndex % u32(uniforms.gridSize.x)),
        f32(instanceIndex / u32(uniforms.gridSize.x))
      );
      let worldPosition = (vertexPosition * uniforms.tileSize) + (gridPosition * uniforms.tileSize);
      let viewPosition = worldPosition - uniforms.cameraPos;

      // Convert to clip space
      let clipPosition = (viewPosition / uniforms.screenSize) * 2.0 - 1.0;

      var output: VertexOutput;
      output.position = vec4f(clipPosition, 0.0, 1.0);
      output.vertexPosition = vertexPosition;
      output.tileState = tileStates[instanceIndex];
      return output;
    }

    @fragment
    fn fragmentShader(input: VertexOutput) -> @location(0) vec4f {
      // Calculate the texture coordinates within the 4x4 grid
      // Each subtexture is 32x32 pixels in a 128x128 texture
      let subtextureSize = 1.0 / 4.0; // 32 / 128 = 1/4

      // Calculate the position in the 4x4 texture grid
      let textureGridPos = vec2f(
        f32(input.tileState % 4u) * subtextureSize,
        f32(input.tileState / 4u) * subtextureSize
      );

      // Calculate the texture coordinates within the 4x4 grid
      // Flip the Y-coordinate by subtracting from 1.0 (otherwise the texture will be upside down)
      let tileTexCoord = vec2f(
        textureGridPos.x + input.vertexPosition.x * subtextureSize,
        textureGridPos.y + (1.0 - input.vertexPosition.y) * subtextureSize
      );

      return textureSample(tileTexture, tileSampler, tileTexCoord);
    }
    `;
  }

  private draw(): void {
    if (!this.device) {
      console.error("Device not initialized.");
      return;
    }
    if (!this.context) {
      console.error("Context not initialized.");
      return;
    }
    if (!this.pipeline) {
      console.error("Pipeline not initialized.");
      return;
    }
    if (!this.bindGroup) {
      console.error("BindGroup not initialized.");
      return;
    }
    if (!this.uniformBuffer) {
      console.error("UniformBuffer not initialized.");
      return;
    }
    if (!this.vertexBuffer) {
      console.error("VertexBuffer not initialized.");
      return;
    }

    const commandEncoder: GPUCommandEncoder = this.device.createCommandEncoder();
    const textureView: GPUTextureView = this.context.getCurrentTexture().createView();

    const renderPassDescriptor: GPURenderPassDescriptor = {
      colorAttachments: [
        {
          view: textureView,
          clearValue: { r: 0.0, g: 0.0, b: 0.0, a: 1.0 },
          loadOp: "clear",
          storeOp: "store",
        },
      ],
    };

    const passEncoder: GPURenderPassEncoder = commandEncoder.beginRenderPass(renderPassDescriptor);
    passEncoder.setPipeline(this.pipeline);
    passEncoder.setBindGroup(0, this.bindGroup);
    passEncoder.setVertexBuffer(0, this.vertexBuffer);
    passEncoder.draw(6, this.GRID_TILES_WIDTH * this.GRID_TILES_HEIGHT);
    passEncoder.end();

    this.device.queue.submit([commandEncoder.finish()]);

    // loop
    this.drawAnimationFrameId = requestAnimationFrame(this.draw.bind(this));
  }

  private updateUniformBuffer(): void {
    if (!this.device || !this.uniformBuffer || !this.canvas) return;

    const uniforms = new Float32Array(this.UNIFORM_BUFFER_SIZE / 4);
    uniforms.set([
      this.GRID_TILES_WIDTH,
      this.GRID_TILES_HEIGHT,
      this.GRID_TILES_WIDTH * this.tileSize,
      this.GRID_TILES_HEIGHT * this.tileSize,
      this.cameraWorldX,
      this.cameraWorldY,
      this.canvas.width,
      this.canvas.height,
      this.tileSize,
      0, // padding to reach 40 bytes
    ]);

    this.device.queue.writeBuffer(this.uniformBuffer, 0, uniforms);
  }

  public updateInstanceBuffer(tileStates: Uint32Array): void {
    if (!this.device || !this.instanceBuffer) {
      throw new Error("Device or instanceBuffer not initialized.");
    }

    this.device.queue.writeBuffer(this.instanceBuffer, 0, tileStates);
  }

  private async loadTexture(url: string): Promise<GPUTexture> {
    if (!this.device) {
      throw new Error("Device not initialized.");
    }

    const response: Response = await fetch(url);
    const imageBitmap: ImageBitmap = await createImageBitmap(await response.blob());

    const texture: GPUTexture = this.device.createTexture({
      size: [imageBitmap.width, imageBitmap.height, 1],
      format: "rgba8unorm",
      usage: GPUTextureUsage.TEXTURE_BINDING | GPUTextureUsage.COPY_DST | GPUTextureUsage.RENDER_ATTACHMENT,
    });

    this.device.queue.copyExternalImageToTexture(
      { source: imageBitmap },
      { texture: texture },
      [imageBitmap.width, imageBitmap.height],
    );

    return texture;
  }

  private clampCamera(): void {
    if (!this.canvas) {
      throw new Error("Canvas not initialized.");
    }

    const worldWidth = this.GRID_TILES_WIDTH * this.tileSize;
    const worldHeight = this.GRID_TILES_HEIGHT * this.tileSize;
    const maxX = worldWidth - this.canvas.width;
    const maxY = worldHeight - this.canvas.height;

    let newX: number;
    if (worldWidth < this.canvas.width) {
      // TODO - should I offset in shader? that might mess up mouse clicking
      newX = (worldWidth / 2) - (this.canvas.width / 2);
    } else {
      newX = Math.max(0, Math.min(this.cameraWorldX, maxX));
    }

    let newY: number;
    if (worldHeight < this.canvas.height) {
      // TODO - should I offset in shader? that might mess up mouse clicking
      newY = (worldHeight / 2) - (this.canvas.height / 2);
    } else {
      newY = Math.max(0, Math.min(this.cameraWorldY, maxY));
    }

    if (newX !== this.cameraWorldX) {
      this.cameraWorldX = newX;
    }
    if (newY !== this.cameraWorldY) {
      this.cameraWorldY = newY;
    }
  }

  private initMovementListeners(): void {
    const keys = new Set<string>();

    globalThis.addEventListener("keydown", (event: KeyboardEvent) => {
      keys.add(event.key.toLowerCase());
    });

    globalThis.addEventListener("keyup", (event: KeyboardEvent) => {
      keys.delete(event.key.toLowerCase());
    });

    let lastUpdateTime = performance.now();
    const updateInterval = 1000 / 60; // 60 FPS

    const move = (currentTime: number) => {
      if (currentTime - lastUpdateTime < updateInterval) {
        this.movementAnimationFrameId = requestAnimationFrame(move);
        return;
      }

      let dx = 0;
      let dy = 0;

      if (keys.has("w") || keys.has("arrowup")) dy -= this.MOVE_SPEED;
      if (keys.has("s") || keys.has("arrowdown")) dy += this.MOVE_SPEED;
      if (keys.has("a") || keys.has("arrowleft")) dx -= this.MOVE_SPEED;
      if (keys.has("d") || keys.has("arrowright")) dx += this.MOVE_SPEED;

      if (dx !== 0 || dy !== 0) {
        // Normalize the movement vector so that diagonal movement isn't faster
        // TODO - fix bug where if colliding with edge of grid, camera moves slower
        const length = Math.sqrt(dx * dx + dy * dy);
        dx = (dx / length) * this.MOVE_SPEED;
        dy = (dy / length) * this.MOVE_SPEED;

        this.cameraWorldX = this.cameraWorldX + dx;
        this.cameraWorldY = this.cameraWorldY - dy;
        this.clampCamera();

        this.updateUniformBuffer();
      }

      lastUpdateTime = currentTime;
      this.movementAnimationFrameId = requestAnimationFrame(move);
    };

    move(lastUpdateTime);
  }

  private initMouseWheelListener() {
    if (!this.canvas) {
      throw new Error("Canvas not initialized.");
    }

    this.canvas.addEventListener("wheel", (event: WheelEvent) => {
      event.preventDefault();

      // Store the mouse position relative to the canvas
      const canvasRect = (event.target as HTMLElement).getBoundingClientRect();
      const mouseX = event.clientX - canvasRect.left;
      const mouseY = event.clientY - canvasRect.top;

      // Calculate world coordinates of the mouse before zooming
      const worldXBeforeZoom = this.cameraWorldX + mouseX;
      const worldYBeforeZoom = this.cameraWorldY + mouseY;

      // Update tile size based on scroll direction
      const direction = Math.sign(event.deltaY);
      let newTileSize = this.tileSize - (direction * this.ZOOM_SPEED);
      newTileSize = Math.max(this.MIN_TILE_SIZE, Math.min(newTileSize, this.MAX_TILE_SIZE));
      if (newTileSize === this.tileSize) return;

      // Calculate the zoom factor
      const zoomFactor = newTileSize / this.tileSize;

      // Adjust camera position to keep the mouse point fixed
      const newCameraWorldX = worldXBeforeZoom - (mouseX * zoomFactor);
      const newCameraWorldY = worldYBeforeZoom - (mouseY * zoomFactor);

      // Clamp camera position to ensure it doesn't go out of bounds
      this.cameraWorldX = newCameraWorldX;
      this.cameraWorldY = newCameraWorldY;
      this.tileSize = newTileSize;
      this.clampCamera();

      this.updateUniformBuffer();
    }, { passive: false });
  }

  private initMouseClickListener(): void {
    if (!this.canvas) {
      throw new Error("Canvas not initialized.");
    }

    // prevent right-click context menu
    this.canvas.addEventListener("contextmenu", (event) => {
      event.preventDefault();
    });

    this.canvas.addEventListener("mousedown", (mouseEvent: MouseEvent) => {
      // left click
      if (mouseEvent.button === 0) {
        if (this.callbackRevealTile === null) {
          return;
        }

        const tileIndex: number = this.getMouseTileIndex(mouseEvent);
        this.callbackRevealTile(tileIndex);
      }

      // right click
      if (mouseEvent.button === 2) {
        if (this.callbackFlagTile === null) {
          return;
        }

        const tileIndex: number = this.getMouseTileIndex(mouseEvent);
        this.callbackFlagTile(tileIndex);
      }
    });
  }

  private getMouseTileIndex(mouseEvent: MouseEvent): number {
    const [gridX, gridY] = this.getMouseGridPosition(mouseEvent);
    if (gridX < 0 || gridX >= this.GRID_TILES_WIDTH || gridY < 0 || gridY >= this.GRID_TILES_HEIGHT) {
      throw new Error(`Invalid grid position: (${gridX}, ${gridY})`);
    }

    return gridY * this.GRID_TILES_WIDTH + gridX;
  }

  private getMouseGridPosition(mouseEvent: MouseEvent): [number, number] {
    const [mouseWorldX, mouseWorldY] = this.getMouseWorldPosition(mouseEvent);

    const gridX = Math.floor(mouseWorldX / this.tileSize);
    const gridY = Math.floor(mouseWorldY / this.tileSize);

    // TODO - delete
    console.debug(`Grid (x, y): (${gridX}, ${gridY})\n`);

    return [gridX, gridY];
  }

  private getMouseWorldPosition(mouseEvent: MouseEvent): [number, number] {
    if (!this.canvas) {
      throw new Error("Canvas not initialized.");
    }

    const dpi = globalThis.devicePixelRatio || 1;

    const mouseOffsetX = Math.floor(mouseEvent.offsetX * dpi);
    const mouseOffsetY = Math.floor(mouseEvent.offsetY * dpi);

    const mouseWorldX = mouseOffsetX + this.cameraWorldX;
    const mouseWorldY = this.canvas.height - mouseOffsetY + this.cameraWorldY;

    // TODO - delete
    console.debug(
      `dpi: ${dpi}\n
Canvas width/height: ${this.canvas.width}x${this.canvas.height} (pixels)\n
Mouse Offset (x, y): (${mouseOffsetX}, ${mouseOffsetY})\n
Camera (x, y): (${this.cameraWorldX}, ${this.cameraWorldY})\n
Mouse World (x, y): (${mouseWorldX}, ${mouseWorldY})\n`,
    );

    return [mouseWorldX, mouseWorldY];
  }
}
