import ByteReader from "./byte-reader.ts";
import ByteWriter from "./byte-writer.ts";

export enum IncomingPayloadType {
  Pong = 0,
  Error = 1,
  LoggedIn = 2,
  LoggedOut = 3,
  CreatedPrivateGame = 4,
  JoinedPrivateGame = 5,
  PlayerJoinedPrivateGame = 6,
  PlayerLeftPrivateGame = 7,
  StartedPrivateGame = 8,
  GameStateUpdate = 9,
  GameOver = 10,
}

export type AccountPayload = { accountId: string; username: string };

export type PlayerOwnedTileUpdates = { playerId: number; tileUpdates: TileUpdate[] };
export type TileUpdate = { index: number; newType: number };

export enum WinCondition {
  LastPlayerAlive = 0,
  EntireBoardRevealedOrFlagged = 1,
  TimeLimit = 2,
}

export type IncomingPayload =
  | { type: IncomingPayloadType.Pong }
  | { type: IncomingPayloadType.Error; originPayloadType: number; message: string }
  | { type: IncomingPayloadType.LoggedIn; accountId: string; username: string }
  | { type: IncomingPayloadType.LoggedOut }
  | { type: IncomingPayloadType.CreatedPrivateGame; gameId: string; joinCode: string }
  | { type: IncomingPayloadType.JoinedPrivateGame; gameId: string; joinCode: string; lobby: AccountPayload[] }
  | { type: IncomingPayloadType.PlayerJoinedPrivateGame; gameId: string; accountId: string; username: string }
  | { type: IncomingPayloadType.PlayerLeftPrivateGame; gameId: string; accountId: string }
  | {
    type: IncomingPayloadType.StartedPrivateGame;
    gameId: string;
    width: number;
    height: number;
    maxDurationSeconds: number;
  }
  | {
    type: IncomingPayloadType.GameStateUpdate;
    gameId: string;
    playerOwnedTileUpdates: PlayerOwnedTileUpdates[];
    deadPlayers: number[];
  }
  | { type: IncomingPayloadType.GameOver; gameId: string; winner: number; winCondition: WinCondition };

export class IncomingPayloadSerializer {
  public static toBytes(payload: IncomingPayload): Uint8Array {
    const byteWriter: ByteWriter = new ByteWriter();
    switch (payload.type) {
      case IncomingPayloadType.Pong: {
        byteWriter.writeU8(IncomingPayloadType.Pong);
        break;
      }
      case IncomingPayloadType.Error: {
        byteWriter.writeU8(IncomingPayloadType.Error);
        byteWriter.writeU8(payload.originPayloadType);
        byteWriter.writeString(payload.message);
        break;
      }
      case IncomingPayloadType.LoggedIn: {
        byteWriter.writeU8(IncomingPayloadType.LoggedIn);
        byteWriter.writeUuid(payload.accountId);
        byteWriter.writeString(payload.username);
        break;
      }
      case IncomingPayloadType.LoggedOut: {
        byteWriter.writeU8(IncomingPayloadType.LoggedOut);
        break;
      }
      case IncomingPayloadType.CreatedPrivateGame: {
        byteWriter.writeU8(IncomingPayloadType.CreatedPrivateGame);
        byteWriter.writeUuid(payload.gameId);
        byteWriter.writeString(payload.joinCode);
        break;
      }
      case IncomingPayloadType.JoinedPrivateGame: {
        byteWriter.writeU8(IncomingPayloadType.JoinedPrivateGame);
        byteWriter.writeUuid(payload.gameId);
        byteWriter.writeString(payload.joinCode);

        // lobby
        byteWriter.writeU8(payload.lobby.length);
        for (const account of payload.lobby) {
          byteWriter.writeUuid(account.accountId);
          byteWriter.writeString(account.username);
        }
        break;
      }
      case IncomingPayloadType.PlayerJoinedPrivateGame: {
        byteWriter.writeU8(IncomingPayloadType.PlayerJoinedPrivateGame);
        byteWriter.writeUuid(payload.gameId);
        byteWriter.writeUuid(payload.accountId);
        byteWriter.writeString(payload.username);
        break;
      }
      case IncomingPayloadType.PlayerLeftPrivateGame: {
        byteWriter.writeU8(IncomingPayloadType.PlayerLeftPrivateGame);
        byteWriter.writeUuid(payload.gameId);
        byteWriter.writeUuid(payload.accountId);
        break;
      }
      case IncomingPayloadType.StartedPrivateGame: {
        byteWriter.writeU8(IncomingPayloadType.StartedPrivateGame);
        byteWriter.writeUuid(payload.gameId);
        byteWriter.writeU16(payload.width);
        byteWriter.writeU16(payload.height);
        byteWriter.writeU16(payload.maxDurationSeconds);
        break;
      }
      case IncomingPayloadType.GameStateUpdate: {
        byteWriter.writeU8(IncomingPayloadType.GameStateUpdate);
        byteWriter.writeUuid(payload.gameId);

        // playerOwnedTileUpdates
        byteWriter.writeU8(payload.playerOwnedTileUpdates.length);
        for (const playerOwnedTileUpdate of payload.playerOwnedTileUpdates) {
          byteWriter.writeU8(playerOwnedTileUpdate.playerId);

          // tileUpdates
          byteWriter.writeU32(playerOwnedTileUpdate.tileUpdates.length);
          for (const tileUpdate of playerOwnedTileUpdate.tileUpdates) {
            byteWriter.writeU32(tileUpdate.index);
            byteWriter.writeU8(tileUpdate.newType);
          }
        }

        // deadPlayers
        byteWriter.writeU8(payload.deadPlayers.length);
        for (const player of payload.deadPlayers) {
          byteWriter.writeU8(player);
        }
        break;
      }
      case IncomingPayloadType.GameOver: {
        byteWriter.writeU8(IncomingPayloadType.GameOver);
        byteWriter.writeUuid(payload.gameId);
        byteWriter.writeU8(payload.winner);
        byteWriter.writeU8(payload.winCondition);
        break;
      }
    }
    return byteWriter.getBytes();
  }

  public static fromBytes(bytes: Uint8Array): IncomingPayload {
    const byteReader: ByteReader = new ByteReader(bytes);
    if (byteReader.isEmpty()) {
      throw new Error("IncomingPayload: empty byte array");
    }

    let result: IncomingPayload;
    const payloadType: number = byteReader.readU8();
    switch (payloadType) {
      case IncomingPayloadType.Pong: {
        result = { type: payloadType };
        break;
      }
      case IncomingPayloadType.Error: {
        result = {
          type: payloadType,
          originPayloadType: byteReader.readU8(),
          message: byteReader.readString(),
        };
        break;
      }
      case IncomingPayloadType.LoggedIn: {
        result = {
          type: payloadType,
          accountId: byteReader.readUuid(),
          username: byteReader.readString(),
        };
        break;
      }
      case IncomingPayloadType.LoggedOut: {
        result = { type: payloadType };
        break;
      }
      case IncomingPayloadType.CreatedPrivateGame: {
        result = {
          type: payloadType,
          gameId: byteReader.readUuid(),
          joinCode: byteReader.readString(),
        };
        break;
      }
      case IncomingPayloadType.JoinedPrivateGame: {
        const gameId: string = byteReader.readUuid();
        const joinCode: string = byteReader.readString();

        const lobby: AccountPayload[] = [];
        const lobbyLength: number = byteReader.readU8();
        for (let i = 0; i < lobbyLength; i++) {
          lobby.push({
            accountId: byteReader.readUuid(),
            username: byteReader.readString(),
          });
        }

        result = { type: payloadType, gameId, joinCode, lobby };
        break;
      }
      case IncomingPayloadType.PlayerJoinedPrivateGame: {
        result = {
          type: payloadType,
          gameId: byteReader.readUuid(),
          accountId: byteReader.readUuid(),
          username: byteReader.readString(),
        };
        break;
      }
      case IncomingPayloadType.PlayerLeftPrivateGame: {
        result = {
          type: payloadType,
          gameId: byteReader.readUuid(),
          accountId: byteReader.readUuid(),
        };
        break;
      }
      case IncomingPayloadType.StartedPrivateGame: {
        result = {
          type: payloadType,
          gameId: byteReader.readUuid(),
          width: byteReader.readU16(),
          height: byteReader.readU16(),
          maxDurationSeconds: byteReader.readU16(),
        };
        break;
      }
      case IncomingPayloadType.GameStateUpdate: {
        const gameId: string = byteReader.readUuid();

        const playerOwnedTileUpdates: PlayerOwnedTileUpdates[] = [];
        const playerOwnedTileUpdatesLength: number = byteReader.readU8();
        for (let i = 0; i < playerOwnedTileUpdatesLength; i++) {
          const playerId: number = byteReader.readU8();

          const tileUpdates: TileUpdate[] = [];
          const tileUpdatesLength: number = byteReader.readU32();
          for (let j = 0; j < tileUpdatesLength; j++) {
            tileUpdates.push({
              index: byteReader.readU32(),
              newType: byteReader.readU8(),
            });
          }

          playerOwnedTileUpdates.push({ playerId, tileUpdates });
        }

        const deadPlayers: number[] = [];
        const deadPlayersLength: number = byteReader.readU8();
        for (let i = 0; i < deadPlayersLength; i++) {
          deadPlayers.push(byteReader.readU8());
        }

        result = { type: payloadType, gameId, playerOwnedTileUpdates, deadPlayers };
        break;
      }
      case IncomingPayloadType.GameOver: {
        result = {
          type: payloadType,
          gameId: byteReader.readUuid(),
          winner: byteReader.readU8(),
          winCondition: byteReader.readU8(),
        };
        break;
      }

      default: {
        throw new Error(`IncomingPayload: unknown message type: '${bytes[0]}'`);
      }
    }

    // verify that the entire byte array was consumed
    if (!byteReader.isEmpty()) {
      throw new Error("IncomingPayload: unexpected bytes");
    }

    return result;
  }
}
