// types.ts
type SoundEffect = {
  url: string;
  volume?: number;
  loop?: boolean;
};

type SoundBank = {
  [key: string]: SoundEffect;
};

// AudioManager.ts
export class AudioManager {
  private soundBank: SoundBank;
  private audioContext: AudioContext | null;
  private masterVolume: GainNode | null;
  private audioBuffers: Map<string, AudioBuffer>;
  private activeAudio: Map<string, GainNode>;

  constructor(soundBank: SoundBank) {
    this.soundBank = soundBank;
    this.audioContext = null;
    this.masterVolume = null;
    this.audioBuffers = new Map();
    this.activeAudio = new Map();
  }

  initAudioContext() {
    if (this.audioContext !== null) {
      console.error("AudioContext already initialized.");
      return;
    }

    this.audioContext = new AudioContext();
    this.masterVolume = this.audioContext.createGain();
    this.masterVolume.connect(this.audioContext.destination);
  }

  async loadSound(soundId: string) {
    if (this.audioBuffers.has(soundId)) {
      return;
    }

    if (this.audioContext === null) {
      this.initAudioContext();
    }

    const sound: SoundEffect = this.soundBank[soundId];
    try {
      const response: Response = await fetch(sound.url);
      const arrayBuffer: ArrayBuffer = await response.arrayBuffer();
      const audioBuffer: AudioBuffer = await this.audioContext!.decodeAudioData(arrayBuffer);
      this.audioBuffers.set(soundId, audioBuffer);
    } catch (error) {
      console.error(`Failed to load sound: ${soundId}`, error);
    }
  }

  async play(soundId: string, options: { volume?: number; loop?: boolean } = {}) {
    if (!this.audioContext) {
      this.initAudioContext();
    }
    if (!this.audioBuffers.has(soundId)) {
      await this.loadSound(soundId);
    }
    const soundEffect: SoundEffect = this.soundBank[soundId];
    const buffer: AudioBuffer = this.audioBuffers.get(soundId)!;

    const source: AudioBufferSourceNode = this.audioContext!.createBufferSource();
    source.buffer = buffer;
    source.loop = options.loop ?? soundEffect.loop ?? false;

    const gainNode: GainNode = this.audioContext!.createGain();
    gainNode.gain.value = options.volume ?? soundEffect.volume ?? 1;

    source.connect(gainNode);
    gainNode.connect(this.masterVolume!);

    source.start(0);
    this.activeAudio.set(soundId, gainNode);

    source.onended = () => {
      if (!options.loop) {
        this.activeAudio.delete(soundId);
      }
    };

    return {
      stop: () => {
        source.stop();
        this.activeAudio.delete(soundId);
      },
      setVolume: (volume: number) => {
        gainNode.gain.value = volume;
      },
    };
  }

  stopAll() {
    this.activeAudio.clear();
    if (this.audioContext) {
      this.audioContext.close();
    }
  }

  setMasterVolume(volume: number) {
    if (this.masterVolume) {
      this.masterVolume.gain.value = Math.max(0, Math.min(1, volume));
    }
  }
}
