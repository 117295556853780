export default class WebsocketWrapper {
  private readonly debug: boolean;
  private readonly url: string;
  private websocket: WebSocket | null;
  private reconnectionTimerId: number | null;
  private readonly reconnectTimeoutMilliseconds: number;

  // callbacks for custom behaviour
  private callbackAttemptConnection: (() => void) | null;
  private callbackSuccessfullyConnected: (() => void) | null;
  private callbackStartReconnectionLoop: (() => void) | null;
  private callbackOnMessage: ((messageEvent: MessageEvent) => void) | null;

  constructor(
    debug: boolean,
    url: string,
    reconnectTimeoutMilliseconds: number,
  ) {
    this.debug = debug;
    this.url = url;
    this.websocket = null;
    this.reconnectionTimerId = null;
    this.reconnectTimeoutMilliseconds = reconnectTimeoutMilliseconds;

    // callbacks for custom behaviour
    this.callbackAttemptConnection = null;
    this.callbackSuccessfullyConnected = null;
    this.callbackStartReconnectionLoop = null;
    this.callbackOnMessage = null;
  }

  public set attemptConnectionCallback(callback: (() => void) | null) {
    this.callbackAttemptConnection = callback;
  }

  public set successfullyConnectedCallback(callback: (() => void) | null) {
    this.callbackSuccessfullyConnected = callback;
  }

  public set startReconnectionLoopCallback(callback: (() => void) | null) {
    this.callbackStartReconnectionLoop = callback;
  }

  public set onMessageCallback(callback: ((messageEvent: MessageEvent) => void) | null) {
    this.callbackOnMessage = callback;
  }

  public connect() {
    // let consumer of this class do something when a fresh connection is attempted
    if (this.callbackAttemptConnection !== null) {
      this.callbackAttemptConnection();
    }

    // make sure any existing websocket connection is closed
    this.disconnect();

    // create new websocket connection
    if (this.debug) console.debug(`Attempting to connect websocket to '${this.url}'...`);
    this.websocket = new WebSocket(this.url);

    // attach websocket handlers
    this.websocket.onopen = this.onOpen.bind(this);
    this.websocket.onmessage = this.onMessage.bind(this);
    this.websocket.onclose = this.onClose.bind(this);
    this.websocket.onerror = this.onError.bind(this);
  }

  public disconnect() {
    if (this.debug) console.debug("Disconnecting websocket...");

    // websocket doesn't even exist, exit early
    if (this.websocket === null) {
      return;
    }

    // if websocket is connecting/open, force close it
    const currentState: number = this.websocket.readyState;
    if (currentState === WebSocket.CONNECTING || currentState === WebSocket.OPEN) {
      if (this.debug) console.debug("Force closing the websocket.");
      this.websocket.close();
    }

    // clean up websocket handlers
    this.websocket.onopen = null;
    this.websocket.onmessage = null;
    this.websocket.onclose = null;
    this.websocket.onerror = null;

    // delete reference to old (and now closed) websocket object
    this.websocket = null;

    if (this.debug) console.debug("Websocket disconnected.");
  }

  public send(payload: string | ArrayBuffer) {
    // websocket doesn't exist, exit early
    if (this.websocket === null) {
      if (this.debug) console.warn("Attempted to send a websocket payload, but the websocket was `null`.");
      return;
    }

    // send the payload
    this.websocket.send(payload);
  }

  private startReconnectionLoop() {
    // there is already an existing reconnect loop timer, exit early
    if (this.reconnectionTimerId !== null) {
      return;
    }

    // let the consumer of this class do something when we've lost the connection and attempt to reconnect
    if (this.callbackStartReconnectionLoop !== null) {
      this.callbackStartReconnectionLoop();
    }

    // make sure any existing websocket connection is closed
    this.disconnect();

    // attempt reconnecting the websocket connection every 'reconnectTimeoutMilliseconds' seconds
    this.reconnectionTimerId = setInterval(this.connect.bind(this), this.reconnectTimeoutMilliseconds);
  }

  private stopReconnectionLoop() {
    // if there is no reconnect timer, exit early
    if (this.reconnectionTimerId === null) {
      if (this.debug) {
        console.warn(
          "Attempted to stop the reconnection loop, but there was no reconnection loop timer.",
        );
      }
      return;
    }

    // if websocket doesn't even exist, exit early
    if (this.websocket === null) {
      if (this.debug) console.warn("Attempted to stop the reconnection loop, but the websocket was `null`.");
      return;
    }

    // if the websocket is connecting/closing/closed, exit early
    const wsState: number = this.websocket.readyState;
    if (wsState !== WebSocket.OPEN) {
      if (this.debug) {
        console.warn(
          "Attempted to stop the reconnection loop, " +
            "but the websocket was already in the connecting/closing/closed state.",
        );
      }
      return;
    }

    // websocket connection was re-established, delete reconnect loop timer
    clearInterval(this.reconnectionTimerId);
    this.reconnectionTimerId = null;
  }

  private onOpen(event: Event) {
    if (this.debug) console.debug("Websocket connection opened:", event);

    // if we were in a websocket reconnect loop, end it
    this.stopReconnectionLoop();

    if (this.callbackSuccessfullyConnected !== null) {
      this.callbackSuccessfullyConnected();
    }
  }

  private onMessage(messageEvent: MessageEvent) {
    if (this.debug) console.debug("Websocket received message:", messageEvent);

    // let the consumer of this class do something when we've received a message
    if (this.callbackOnMessage !== null) {
      this.callbackOnMessage(messageEvent);
    }
  }

  private onClose(closeEvent: CloseEvent) {
    if (this.debug) console.warn("Websocket connection closed:", closeEvent);

    // set the websocket to null to garbage collect it, then start the reconnection loop
    this.websocket = null;
    this.startReconnectionLoop();
  }

  private onError(event: Event) {
    if (this.debug) console.error("Websocket encountered an error:", event);

    // if any error occurs, immediately disconnect
    // this will close the websocket connection and start the reconnection loop
    this.disconnect();
  }
}
