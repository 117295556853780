import ApiClient from "./api-client.ts";
import { AppState } from "./app-state.ts";
import { AudioManager } from "./audio-manager.ts";
import { AccountPayload, PlayerOwnedTileUpdates, WinCondition } from "./codec/incoming-payload.ts";
import Renderer from "./renderer.ts";
import { ToastManager, ToastType } from "./toast-manager.ts";
import WebsocketWrapper from "./websocket-wrapper.ts";

export class Game {
  private readonly debug: boolean = true;
  private readonly appState: AppState;
  private readonly apiClient: ApiClient;
  private readonly toastManager: ToastManager;

  // websocket
  private readonly ws: WebsocketWrapper;
  private isFirstConnectionAttempt: boolean = true; // (don't show reconnection toast when player firsts loads game)

  // ping
  private pingTimerId: number | null = null;
  private readonly pingTimeoutMilliseconds: number = 60 * 1000;
  private pingElapsedMilliseconds: number = performance.now();

  // game renderer
  private readonly parentElement: HTMLElement;
  private renderer: Renderer | null;

  // audio
  private readonly audioManager: AudioManager;

  constructor() {
    // check for WebGPU support
    if (!navigator.gpu) {
      this.showModalUnsupportedWebGpu();
      throw new Error("WebGPU not supported on this browser.");
    }

    // audio
    this.audioManager = new AudioManager({
      explosion: {
        url: "static/audio/explosion.wav",
        volume: 0.25,
      },
      reveal: {
        url: "static/audio/reveal.wav",
        volume: 1.0,
      },
      plant: {
        url: "static/audio/plant.wav",
        volume: 0.25,
      },
      click: {
        url: "static/audio/click.wav",
        volume: 1.0,
      },
    });

    // grab the main element for the canvas
    const mainElement = document.querySelector("main");
    if (mainElement === null) {
      throw new Error("No <main> element found in the document.");
    }
    this.parentElement = mainElement;

    // init toast manager
    this.toastManager = new ToastManager();

    // init app state
    this.appState = new AppState();

    // init ws
    const wsUrl = `${
      globalThis.location.protocol.startsWith("https") ? "wss" : "ws"
    }://${globalThis.location.host}/api/v1/ws/proxy`;
    this.ws = new WebsocketWrapper(this.debug, wsUrl, 10 * 1000);
    this.ws.attemptConnectionCallback = this.handleWebsocketConnectionAttempt.bind(this);
    this.ws.successfullyConnectedCallback = this.handleNewWebsocketConnection.bind(this);
    this.ws.startReconnectionLoopCallback = this.handleWebsocketDisconnection.bind(this);

    // init api client
    this.apiClient = new ApiClient(this.debug, this.ws);

    // set up ping callback
    this.apiClient.callbackPong = this.handleApiPong.bind(this);

    // set up error callback
    this.apiClient.callbackError = this.handleApiError.bind(this);

    // connect to the websocket
    this.ws.connect();

    // init UI
    this.renderer = null;
    this.showLogInModal();
  }

  private handleNewWebsocketConnection(): void {
    console.log("Websocket connection established");
    this.toastManager.add("Connected!", { type: ToastType.SUCCESS });

    // start the ping timer
    this.handleApiPing();
    this.pingTimerId = setInterval(this.handleApiPing.bind(this), this.pingTimeoutMilliseconds);
  }

  private handleWebsocketDisconnection(): void {
    console.log("Websocket connection lost");
    this.toastManager.add("Disconnected...", { type: ToastType.ERROR });

    // stop the ping timer
    if (this.pingTimerId !== null) {
      clearInterval(this.pingTimerId);
      this.pingTimerId = null;
    }
  }

  private handleWebsocketConnectionAttempt(): void {
    if (this.isFirstConnectionAttempt) {
      this.isFirstConnectionAttempt = false;
      return;
    }

    console.log("Attempting to connect to the websocket");
    this.toastManager.add("Attempting reconnection...", { type: ToastType.WARNING });
  }

  private handleApiPing(): void {
    this.pingElapsedMilliseconds = performance.now();
    this.apiClient.ping();
  }

  private handleApiPong(): void {
    console.log(`Ping: ${performance.now() - this.pingElapsedMilliseconds}ms`);
  }

  private handleApiError(originPayloadType: number, message: string): void {
    console.error(`API Error ('${originPayloadType}'): ${message}`);
    this.toastManager.add(message, { type: ToastType.ERROR });
  }

  private showLogInModal(): void {
    // show modal
    const logInModal: HTMLDivElement = document.createElement("div");
    logInModal.classList.add("modal");
    logInModal.innerHTML = `
    <div>
      <h1>Minesweeper Royale</h1>
      <hr>

      <form id="fLogIn">
        <input
          id="iUsername"
          type="text"
          placeholder="Username"
          minlength="1"
          maxlength="16"
          pattern="[a-zA-Z0-9_\\-]{1,16}"
          required
        >
        <input type="submit" value="Play">
      </form>
    </div>
  `;
    document.body.appendChild(logInModal);

    // set up callbacks
    this.apiClient.clearCallbacks();
    // LoggedIn
    this.apiClient.callbackLoggedIn = (accountId: string, username: string) => {
      // save account data
      this.appState.userState.logIn(accountId, username);

      // show next modal
      logInModal.remove();
      this.showModalMainMenu();
    };

    // set up event listeners
    // LogIn
    const fLogIn = document.getElementById("fLogIn");
    if (fLogIn === null) {
      throw new Error("fLogIn not found.");
    }
    if (!(fLogIn instanceof HTMLFormElement)) {
      throw new Error("fLogIn is not an HTMLFormElement.");
    }
    fLogIn.addEventListener("submit", (submitEvent: SubmitEvent) => {
      submitEvent.preventDefault();

      const iUsername = document.getElementById("iUsername");
      if (iUsername === null) {
        throw new Error("iUsername not found.");
      }
      if (!(iUsername instanceof HTMLInputElement)) {
        throw new Error("iUsername is not an HTMLInputElement.");
      }

      // log in
      this.apiClient.logIn(iUsername.value);
      this.audioManager.play("click");
    });
  }

  private showModalMainMenu(): void {
    const mainMenuModal: HTMLDivElement = document.createElement("div");
    mainMenuModal.classList.add("modal");
    mainMenuModal.innerHTML = `
    <div>
      <h1>Minesweeper Royale</h1>
      <hr>

      <form id="fJoinPrivateGame">
        <input
          id="iJoinCode"
          type="text"
          inputmode="numeric"
          placeholder="Game Code"
          maxlength="6"
          pattern="[0-9]{6}"
          autocomplete="one-time-code"
          required
        >
        <input type="submit" value="Join Game">
      </form>
      <form id="fCreatePrivateGame">
        <input type="submit" value="Create Game">
      </form>
      <hr>

      <form id="fLogOut">
        <input type="submit" value="Log Out">
      </form>
    </div>
  `;
    document.body.appendChild(mainMenuModal);

    // set up callbacks
    this.apiClient.clearCallbacks();
    // JoinedPrivateGame
    this.apiClient.callbackJoinedPrivateGame = (gameId: string, joinCode: string, lobby: AccountPayload[]) => {
      // save game data
      this.appState.gameState.joinedPrivateGame(gameId, joinCode, lobby);

      // show next modal
      mainMenuModal.remove();
      this.showModalLobby();
    };
    // CreatedPrivateGame
    this.apiClient.callbackCreatedPrivateGame = (gameId: string, joinCode: string) => {
      // save game data
      if (this.appState.userState.accountId === null || this.appState.userState.username === null) {
        throw new Error("MainMenu: user state is null");
      }
      this.appState.gameState.createdPrivateGame(
        gameId,
        joinCode,
        this.appState.userState.accountId,
        this.appState.userState.username,
      );

      // show next modal
      mainMenuModal.remove();
      this.showModalLobby();
    };
    // LoggedOut
    this.apiClient.callbackLoggedOut = () => {
      // remove account data
      this.appState.userState.logOut();

      // show next modal
      mainMenuModal.remove();
      this.showLogInModal();
    };

    // set up event listeners
    // JoinPrivateGame
    const fJoinPrivateGame = document.getElementById("fJoinPrivateGame");
    if (fJoinPrivateGame === null) {
      throw new Error("fJoinPrivateGame not found.");
    }
    if (!(fJoinPrivateGame instanceof HTMLFormElement)) {
      throw new Error("fJoinPrivateGame is not an HTMLFormElement.");
    }
    fJoinPrivateGame.addEventListener("submit", (submitEvent: SubmitEvent) => {
      submitEvent.preventDefault();

      const iJoinCode = document.getElementById("iJoinCode");
      if (iJoinCode === null) {
        throw new Error("iJoinCode not found.");
      }
      if (!(iJoinCode instanceof HTMLInputElement)) {
        throw new Error("iJoinCode is not an HTMLInputElement.");
      }

      // join private game
      this.apiClient.joinPrivateGame(iJoinCode.value);
      this.audioManager.play("click");
    });
    // CreatePrivateGame
    const fCreatePrivateGame = document.getElementById("fCreatePrivateGame");
    if (fCreatePrivateGame === null) {
      throw new Error("fCreatePrivateGame not found.");
    }
    if (!(fCreatePrivateGame instanceof HTMLFormElement)) {
      throw new Error("fCreatePrivateGame is not an HTMLFormElement.");
    }
    fCreatePrivateGame.addEventListener("submit", (submitEvent: SubmitEvent) => {
      submitEvent.preventDefault();

      // create private game
      this.apiClient.createPrivateGame();
      this.audioManager.play("click");
    });
    // LogOut
    const fLogOut = document.getElementById("fLogOut");
    if (fLogOut === null) {
      throw new Error("fLogOut not found.");
    }
    if (!(fLogOut instanceof HTMLFormElement)) {
      throw new Error("fLogOut is not an HTMLFormElement.");
    }
    fLogOut.addEventListener("submit", (submitEvent: SubmitEvent) => {
      submitEvent.preventDefault();

      // log out
      this.apiClient.logOut();
      this.audioManager.play("click");
    });
  }

  private showModalLobby(): void {
    if (
      this.appState.userState.accountId === null || this.appState.userState.username === null ||
      this.appState.gameState.lobby === null
    ) {
      throw new Error("Lobby: user state is null");
    }
    if (this.appState.gameState.joinCode === null || this.appState.gameState.host === null) {
      throw new Error("Lobby: game state is null");
    }

    // show modal
    const lobbyModal: HTMLDivElement = document.createElement("div");
    lobbyModal.classList.add("modal");
    lobbyModal.innerHTML = `
    <div>
      <h1>Minesweeper Royale</h1>
      <hr>

      <h2>Lobby <span class="sJoinCode">${this.appState.gameState.joinCode}</span></h2>
      <div id="dLobby"></div>
      <hr>

      <form id="fStartGame">
        <input type="submit" value="Start Game">
      </form>
      <hr>

      <form id="fLeaveGame">
        <input type="submit" value="Leave Game">
      </form>
    </div>
    `;
    document.body.appendChild(lobbyModal);

    // fill lobby with already-joined players
    const dLobby = document.getElementById("dLobby");
    if (dLobby === null) {
      throw new Error("dLobby not found.");
    }
    if (!(dLobby instanceof HTMLDivElement)) {
      throw new Error("dLobby is not an HTMLDivElement.");
    }
    for (const player of this.appState.gameState.lobby) {
      const p = document.createElement("p");
      p.dataset.accountId = player.accountId;
      p.textContent = player.username;
      dLobby.appendChild(p);
    }

    // hide start game button if not host
    const fStartGame = document.getElementById("fStartGame");
    if (fStartGame === null) {
      throw new Error("fStartGame not found.");
    }
    if (!(fStartGame instanceof HTMLFormElement)) {
      throw new Error("fStartGame is not an HTMLFormElement.");
    }
    if (this.appState.userState.accountId !== this.appState.gameState.host) {
      fStartGame.style.display = "none";
    }

    // set up callbacks
    this.apiClient.clearCallbacks();
    // PlayerJoinedPrivateGame
    this.apiClient.callbackPlayerJoinedPrivateGame = (gameId: string, accountId: string, username: string) => {
      // save game data
      this.appState.gameState.playerJoinedPrivateGame(gameId, accountId, username);

      // add player to lobby
      const p = document.createElement("p");
      p.dataset.accountId = accountId;
      p.textContent = username;
      dLobby.appendChild(p);
    };
    // PlayerLeftPrivateGame
    this.apiClient.callbackPlayerLeftPrivateGame = (gameId: string, accountId: string) => {
      // save game data
      const lobbyIsEmpty: boolean = this.appState.gameState.playerLeftPrivateGame(gameId, accountId);

      // remove player from lobby
      const p = dLobby.querySelector(`p[data-account-id="${accountId}"]`);
      if (p !== null) {
        p.remove();
      }

      // re-enable the start game button if host
      if (this.appState.userState.accountId === this.appState.gameState.host) {
        fStartGame.style.display = "block";
      }

      // if lobby is empty, or the player that left is the host, delete game data and show main menu
      if (lobbyIsEmpty || accountId === this.appState.userState.accountId) {
        this.appState.gameState.clear();

        // show next modal
        lobbyModal.remove();
        this.showModalMainMenu();
      }
    };
    // StartedPrivateGame
    this.apiClient.callbackStartedPrivateGame = (
      gameId: string,
      width: number,
      height: number,
      maxDurationSeconds: number,
    ) => {
      // save game data
      this.appState.gameState.startedPrivateGame(gameId, width, height, maxDurationSeconds);

      // show next modal
      lobbyModal.remove();
      this.showGameBoard();
    };

    // set up event listeners
    // StartGame (Host only)
    fStartGame.addEventListener("submit", (submitEvent: SubmitEvent) => {
      submitEvent.preventDefault();

      // start private game (Host only)
      if (this.appState.userState.accountId === this.appState.gameState.host) {
        this.apiClient.startPrivateGame();
        this.audioManager.play("click");
      }
    });
    // LeaveGame
    const fLeaveGame = document.getElementById("fLeaveGame");
    if (fLeaveGame === null) {
      throw new Error("fLeaveGame not found.");
    }
    if (!(fLeaveGame instanceof HTMLFormElement)) {
      throw new Error("fLeaveGame is not an HTMLFormElement.");
    }
    fLeaveGame.addEventListener("submit", (submitEvent: SubmitEvent) => {
      submitEvent.preventDefault();

      // leave private game
      this.apiClient.leavePrivateGame();
      this.audioManager.play("click");
    });
  }

  private showGameBoard(): void {
    const boardWidth: number | null = this.appState.gameState.boardWidth;
    const boardHeight: number | null = this.appState.gameState.boardHeight;
    const tileStates: Uint32Array | null = this.appState.gameState.tileStates;
    const currentTimeLeftSeconds: number | null = this.appState.gameState.currentTimeLeftSeconds;
    if (boardWidth === null || boardHeight === null || tileStates === null || currentTimeLeftSeconds === null) {
      throw new Error("Game: game state is null");
    }

    // show game board
    this.renderer = new Renderer(this.parentElement, boardWidth, boardHeight, tileStates);
    this.renderer.callbackRevealTile = (tileIndex: number) => {
      // make sure only hidden tiles are clicked
      const tileStates: Uint32Array | null = this.appState.gameState.tileStates;
      if (tileStates === null) {
        throw new Error("GameBoard: tile states is null");
      }
      if (tileStates[tileIndex] !== 9) {
        return;
      }

      // send payload
      this.apiClient.revealTile(tileIndex);
      this.audioManager.play("reveal");
    };
    this.renderer.callbackFlagTile = (tileIndex: number) => {
      // make sure only hidden/flagged tiles are clicked
      const tileStates: Uint32Array | null = this.appState.gameState.tileStates;
      if (tileStates === null) {
        throw new Error("GameBoard: tile states is null");
      }
      if (tileStates[tileIndex] !== 9 && tileStates[tileIndex] !== 10) {
        return;
      }

      // send payload
      this.apiClient.flagTile(tileIndex);
      this.audioManager.play("plant");
    };

    // show timer panel
    const timerPanel: HTMLDivElement = document.createElement("div");
    timerPanel.id = "timer";
    timerPanel.innerHTML = `${this.prettifySeconds(currentTimeLeftSeconds)}`;
    document.body.appendChild(timerPanel);

    // set up timer
    const timerIntervalId: number = setInterval(() => {
      // update current time left
      this.appState.gameState.countDownCurrentTimeLeft();

      // get latest time left
      const currentTimeLeftSeconds: number | null = this.appState.gameState.currentTimeLeftSeconds;
      if (currentTimeLeftSeconds === null) {
        throw new Error("GameBoard: current time left is null");
      }

      // update timer panel
      timerPanel.innerHTML = this.prettifySeconds(currentTimeLeftSeconds);

      // stop timer if time is up
      if (currentTimeLeftSeconds <= 0) {
        clearInterval(timerIntervalId);
        return;
      }
    }, 1000);

    // set up callbacks
    this.apiClient.clearCallbacks();
    // GameStateUpdate
    this.apiClient.callbackGameStateUpdate = (
      gameId: string,
      playerOwnedTileUpdates: PlayerOwnedTileUpdates[],
      deadPlayers: number[],
    ) => {
      if (this.renderer === null) {
        throw new Error("GameBoard: renderer is null");
      }
      if (gameId !== this.appState.gameState.gameId) {
        throw new Error(`GameBoard: received wrong GameId: '${gameId}' (expected '${this.appState.gameState.gameId}')`);
      }

      // play explosion sound if any mines were hit
      let playedExplosionSound: boolean = false;
      for (const playerOwnedTileUpdate of playerOwnedTileUpdates) {
        for (const tileUpdate of playerOwnedTileUpdate.tileUpdates) {
          if (tileUpdate.newType !== 15) {
            continue;
          }

          this.audioManager.play("explosion");
          playedExplosionSound = true;
          break;
        }

        if (playedExplosionSound) {
          break;
        }
      }

      // save game data
      this.appState.gameState.gameStateUpdate(gameId, playerOwnedTileUpdates, deadPlayers);
      const tileStates: Uint32Array | null = this.appState.gameState.tileStates;
      if (tileStates === null) {
        throw new Error("GameBoard: tile states is null");
      }

      // update game board UI
      this.renderer.updateInstanceBuffer(tileStates);
    };
    // PlayerLeftPrivateGame
    this.apiClient.callbackPlayerLeftPrivateGame = (gameId: string, accountId: string) => {
      if (gameId !== this.appState.gameState.gameId) {
        throw new Error(`GameBoard: received wrong GameId: '${gameId}' (expected '${this.appState.gameState.gameId}')`);
      }

      // save game data
      this.appState.gameState.playerLeftPrivateGame(gameId, accountId);

      // TODO - update UI to show player left
      console.log(`PlayerLeftPrivateGame: '${accountId}'`);
    };
    // GameOver
    this.apiClient.callbackGameOver = (gameId: string, winner: number, winCondition: WinCondition) => {
      if (gameId !== this.appState.gameState.gameId) {
        throw new Error(`GameBoard: received wrong GameId: '${gameId}' (expected '${this.appState.gameState.gameId}')`);
      }

      // stop timer
      clearInterval(timerIntervalId);

      // save game data
      this.appState.gameState.getGameOver(gameId, winner, winCondition);

      // get winner username
      const lobby: AccountPayload[] | null = this.appState.gameState.lobby;
      if (lobby === null) {
        throw new Error("GameBoard: lobby is null");
      }
      const winnerUsername: string = lobby[winner].username;

      // show game over modal
      const gameOverModal: HTMLDivElement = document.createElement("div");
      gameOverModal.classList.add("modal");
      gameOverModal.innerHTML = `
    <div>
      <h2>Game Over</h2>
      <hr>

      <p>Winner: ${winnerUsername}</p>
      <p>Win Condition: ${WinCondition[winCondition]}</p>
      <hr>

      <form id="fMainMenu">
        <input type="submit" value="Main Menu">
      </form>
    </div>
    `;
      document.body.appendChild(gameOverModal);

      // set up event listeners
      const fMainMenu = document.getElementById("fMainMenu");
      if (fMainMenu === null) {
        throw new Error("fMainMenu not found.");
      }
      if (!(fMainMenu instanceof HTMLFormElement)) {
        throw new Error("fMainMenu is not an HTMLFormElement.");
      }
      fMainMenu.addEventListener("submit", (submitEvent: SubmitEvent) => {
        submitEvent.preventDefault();

        // HACK: re-init listener for 'PlayerLeftPrivateGame' because we need to wait for this payload to be received
        // prior to destroying the renderer and showing the main menu
        this.apiClient.callbackPlayerLeftPrivateGame = (gameId: string, accountId: string) => {
          if (gameId !== this.appState.gameState.gameId) {
            throw new Error(
              `GameBoard: received wrong GameId: '${gameId}' (expected '${this.appState.gameState.gameId}')`,
            );
          }
          if (this.appState.userState.accountId !== accountId) {
            return;
          }
          if (this.renderer === null) {
            throw new Error("GameBoard: renderer is null");
          }

          // TODO - delete game data from state?

          // exit back to main menu
          timerPanel.remove();
          gameOverModal.remove();
          this.renderer.destroy();
          this.renderer = null;
          this.showModalMainMenu();
        };

        // leave private game
        this.apiClient.leavePrivateGame();
        this.audioManager.play("click");
      });
    };
  }

  private showModalUnsupportedWebGpu(): void {
    const popUp = document.createElement("div");
    popUp.classList.add("modal");
    popUp.innerHTML = `
    <div>
      <h1>Unsupported WebGPU</h1>
      <br>
      <p>Minesweeper Royale is built with WebGPU which is not available on all browsers (YET).</p>
      <p>Please use Google Chrome on a desktop to play the game.</p>
      <br>
      <p>For more information, please visit <a href="https://caniuse.com/webgpu">caniuse.com/webgpu</a> and <a href="https://webgpu.io">webgpu.io</a>.</p>
      <p>p.s. WebGPU is available on Firefox and Safari behind experimental feature flags!</p>
    </div>
  `;
    document.body.appendChild(popUp);
  }

  private prettifySeconds(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  }

  private TEMP_getTileName(tileState: number): string {
    switch (tileState) {
      case 0:
        return "Revealed";
      case 1:
        return "Flag";
      case 2:
        return "Bomb";
      case 3:
        return "0";
      case 4:
        return "1";
      case 5:
        return "2";
      case 6:
        return "3";
      case 7:
        return "4";
      case 8:
        return "5";
      case 9:
        return "6";
      case 10:
        return "7";
      case 11:
        return "8";
      default:
        return "<invalid>";
    }
  }
}
