type Toast = { id: string; datetime: Date; message: string; element: HTMLElement; options: ToastOptions };
export type ToastOptions = { type: ToastType };
export enum ToastType {
  INFO,
  WARNING,
  ERROR,
  SUCCESS,
}

export class ToastManager {
  private container: HTMLElement;
  private toasts: Toast[] = [];
  private maxToasts: number = 5;
  private duration: number = 4500; // 4.5 seconds

  constructor() {
    // init container
    const container: HTMLElement | null = document.getElementById("toast-container");
    if (container === null) {
      throw new Error("Toast container element not found");
    }
    if (!(container instanceof HTMLDivElement)) {
      throw new Error("Toast container is not an HTMLDivElement");
    }
    this.container = container;

    // init toast removal timer
    globalThis.setInterval(() => {
      this.removeExpired();
    }, 1 * 1000);
  }

  public add(message: string, options: ToastOptions): void {
    const element: HTMLDivElement = document.createElement("div");
    const id: string = crypto.randomUUID();
    const datetime: Date = new Date();
    element.classList.add("toast", "toast-enter");
    element.textContent = message;
    element.dataset.id = id;

    // change color based on type
    switch (options.type) {
      case ToastType.INFO: {
        element.classList.add("info");
        break;
      }
      case ToastType.WARNING: {
        element.classList.add("warning");
        break;
      }
      case ToastType.ERROR: {
        element.classList.add("error");
        break;
      }
      case ToastType.SUCCESS: {
        element.classList.add("success");
        break;
      }
    }

    const toast: Toast = { id, datetime, message, element, options };
    this.toasts.push(toast);
    this.container.appendChild(toast.element);

    // Trigger reflow to ensure the animation plays
    void toast.element.offsetWidth;
    toast.element.classList.add("show");

    if (this.toasts.length > this.maxToasts) {
      this.removeOldest();
    }
  }

  private remove(id: string): void {
    const index: number = this.toasts.findIndex((m) => m.id === id);
    if (index !== -1) {
      const toast: Toast = this.toasts[index];
      toast.element.classList.remove("show");
      toast.element.classList.add("hide");

      toast.element.addEventListener("transitionend", () => {
        this.container.removeChild(toast.element);
        this.toasts.splice(index, 1);
      }, { once: true });
    }
  }

  private removeExpired(): void {
    const currentDatetime: Date = new Date();

    // use functional programming to collect IDs to remove
    const idsToRemove: string[] = this.toasts
      .filter((m) => currentDatetime.getTime() - m.datetime.getTime() >= this.duration)
      .map((m) => m.id);
    idsToRemove.forEach((id) => this.remove(id));
  }

  private removeOldest(): void {
    if (this.toasts.length > 0) {
      this.remove(this.toasts[0].id);
    }
  }
}
