import WebsocketWrapper from "./websocket-wrapper.ts";
import { OutgoingPayload, OutgoingPayloadSerializer, OutgoingPayloadType } from "./codec/outgoing-payload.ts";
import {
  IncomingPayload,
  IncomingPayloadSerializer,
  IncomingPayloadType,
  PlayerOwnedTileUpdates,
  TileUpdate,
  WinCondition,
} from "./codec/incoming-payload.ts";
import { AccountPayload } from "./codec/incoming-payload.ts";

export default class ApiClient {
  private readonly debug: boolean;
  private readonly ws: WebsocketWrapper;

  public callbackPong: (() => void) | null = null;
  public callbackError: ((originPayloadType: number, message: string) => void) | null = null;
  public callbackLoggedIn: ((accountId: string, username: string) => void) | null = null;
  public callbackLoggedOut: (() => void) | null = null;
  public callbackCreatedPrivateGame: ((gameId: string, joinCode: string) => void) | null = null;
  public callbackJoinedPrivateGame: ((gameId: string, joinCode: string, lobby: AccountPayload[]) => void) | null = null;
  public callbackPlayerJoinedPrivateGame: ((gameId: string, accountId: string, username: string) => void) | null = null;
  public callbackPlayerLeftPrivateGame: ((gameId: string, accountId: string) => void) | null = null;
  public callbackStartedPrivateGame:
    | ((gameId: string, width: number, height: number, maxDurationSeconds: number) => void)
    | null = null;
  public callbackGameStateUpdate:
    | ((gameId: string, playerOwnedTileUpdates: PlayerOwnedTileUpdates[], deadPlayers: number[]) => void)
    | null = null;
  public callbackGameOver: ((gameId: string, winner: number, winCondition: WinCondition) => void) | null = null;

  constructor(debug: boolean, ws: WebsocketWrapper) {
    this.debug = debug;
    this.ws = ws;

    this.ws.onMessageCallback = this.onMessage.bind(this);
  }

  public clearCallbacks(): void {
    // don't clear these callbacks because they live for the duration of the app
    // this.callbackPong = null;
    // this.callbackError = null;

    this.callbackLoggedIn = null;
    this.callbackLoggedOut = null;
    this.callbackCreatedPrivateGame = null;
    this.callbackJoinedPrivateGame = null;
    this.callbackPlayerJoinedPrivateGame = null;
    this.callbackPlayerLeftPrivateGame = null;
    this.callbackStartedPrivateGame = null;
    this.callbackGameStateUpdate = null;
  }

  private send(outgoingPayload: OutgoingPayload): void {
    const bytes: Uint8Array = OutgoingPayloadSerializer.toBytes(outgoingPayload);
    this.ws.send(bytes);
  }

  public ping(): void {
    console.debug("ApiClient: Ping");
    this.send({ type: OutgoingPayloadType.Ping });
  }

  public logIn(username: string): void {
    console.debug(`ApiClient: LogIn: username=${username}`);
    this.send({ type: OutgoingPayloadType.LogIn, username });
  }

  public logOut(): void {
    console.debug("ApiClient: LogOut");
    this.send({ type: OutgoingPayloadType.LogOut });
  }

  public createPrivateGame(): void {
    console.debug("ApiClient: CreatePrivateGame");
    this.send({ type: OutgoingPayloadType.CreatePrivateGame });
  }

  public joinPrivateGame(joinCode: string): void {
    console.debug(`ApiClient: JoinPrivateGame: joinCode=${joinCode}`);
    this.send({ type: OutgoingPayloadType.JoinPrivateGame, joinCode });
  }

  public leavePrivateGame(): void {
    console.debug("ApiClient: LeavePrivateGame");
    this.send({ type: OutgoingPayloadType.LeavePrivateGame });
  }

  public startPrivateGame(): void {
    console.debug("ApiClient: StartPrivateGame");
    this.send({ type: OutgoingPayloadType.StartPrivateGame });
  }

  public revealTile(tileIndex: number): void {
    console.debug(`ApiClient: RevealTile: tileIndex=${tileIndex}`);
    this.send({ type: OutgoingPayloadType.RevealTile, index: tileIndex });
  }

  public flagTile(tileIndex: number): void {
    console.debug(`ApiClient: FlagTile: tileIndex=${tileIndex}`);
    this.send({ type: OutgoingPayloadType.FlagTile, index: tileIndex });
  }

  private onMessage(event: MessageEvent): void {
    if (!(event.data instanceof Blob)) {
      console.error("ApiClient: expected Blob, got", event.data);
      return;
    }

    event.data.arrayBuffer().then((buffer: ArrayBuffer) => {
      const bytes: Uint8Array = new Uint8Array(buffer);
      const incomingPayload: IncomingPayload = IncomingPayloadSerializer.fromBytes(bytes);

      switch (incomingPayload.type) {
        case IncomingPayloadType.Pong: {
          if (this.callbackPong !== null) {
            console.debug("ApiClient: Pong");
            this.callbackPong();
            return;
          }
          break;
        }

        case IncomingPayloadType.Error: {
          if (this.callbackError !== null) {
            console.debug(
              `ApiClient: Error: originPayloadType='${incomingPayload.originPayloadType}', message='${incomingPayload.message}'`,
            );
            this.callbackError(incomingPayload.originPayloadType, incomingPayload.message);
            return;
          }
          break;
        }

        case IncomingPayloadType.LoggedIn: {
          if (this.callbackLoggedIn !== null) {
            console.debug(
              `ApiClient: LoggedIn: accountId='${incomingPayload.accountId}', username='${incomingPayload.username}'`,
            );
            this.callbackLoggedIn(incomingPayload.accountId, incomingPayload.username);
            return;
          }
          break;
        }

        case IncomingPayloadType.LoggedOut: {
          if (this.callbackLoggedOut !== null) {
            console.debug("ApiClient: LoggedOut");
            this.callbackLoggedOut();
            return;
          }
          break;
        }

        case IncomingPayloadType.CreatedPrivateGame: {
          if (this.callbackCreatedPrivateGame !== null) {
            console.debug(
              `ApiClient: CreatedPrivateGame: gameId='${incomingPayload.gameId}', joinCode='${incomingPayload.joinCode}'`,
            );
            this.callbackCreatedPrivateGame(incomingPayload.gameId, incomingPayload.joinCode);
            return;
          }
          break;
        }

        case IncomingPayloadType.JoinedPrivateGame: {
          if (this.callbackJoinedPrivateGame !== null) {
            console.debug(
              `ApiClient: JoinedPrivateGame: gameId='${incomingPayload.gameId}', joinCode='${incomingPayload.joinCode}', lobby=[${
                incomingPayload.lobby.map((lobby) => `{accountId='${lobby.accountId}', username='${lobby.username}'}`)
                  .join(", ")
              }]`,
            );
            this.callbackJoinedPrivateGame(incomingPayload.gameId, incomingPayload.joinCode, incomingPayload.lobby);
            return;
          }
          break;
        }

        case IncomingPayloadType.PlayerJoinedPrivateGame: {
          if (this.callbackPlayerJoinedPrivateGame !== null) {
            console.debug(
              `ApiClient: PlayerJoinedPrivateGame: gameId='${incomingPayload.gameId}', accountId='${incomingPayload.accountId}', username='${incomingPayload.username}'`,
            );
            this.callbackPlayerJoinedPrivateGame(
              incomingPayload.gameId,
              incomingPayload.accountId,
              incomingPayload.username,
            );
            return;
          }
          break;
        }

        case IncomingPayloadType.PlayerLeftPrivateGame: {
          if (this.callbackPlayerLeftPrivateGame !== null) {
            console.debug(
              `ApiClient: PlayerLeftPrivateGame: gameId='${incomingPayload.gameId}', accountId='${incomingPayload.accountId}'`,
            );
            this.callbackPlayerLeftPrivateGame(incomingPayload.gameId, incomingPayload.accountId);
            return;
          }
          break;
        }

        case IncomingPayloadType.StartedPrivateGame: {
          if (this.callbackStartedPrivateGame !== null) {
            console.debug(
              `ApiClient: StartedPrivateGame: gameId='${incomingPayload.gameId}', width=${incomingPayload.width}, height=${incomingPayload.height}, maxDurationSeconds=${incomingPayload.maxDurationSeconds}`,
            );
            this.callbackStartedPrivateGame(
              incomingPayload.gameId,
              incomingPayload.width,
              incomingPayload.height,
              incomingPayload.maxDurationSeconds,
            );
            return;
          }
          break;
        }

        case IncomingPayloadType.GameStateUpdate: {
          if (this.callbackGameStateUpdate !== null) {
            console.debug(
              `ApiClient: GameStateUpdate: gameId='${incomingPayload.gameId}', playerOwnedTileUpdates=[${
                incomingPayload.playerOwnedTileUpdates.map((playerOwnedTileUpdate: PlayerOwnedTileUpdates) =>
                  `{playerId='${playerOwnedTileUpdate.playerId}', tiles=[${
                    playerOwnedTileUpdate.tileUpdates.map((tileUpdate: TileUpdate) =>
                      `{index='${tileUpdate.index}', newType='${tileUpdate.newType}'}`
                    ).join(", ")
                  }]}`
                )
                  .join(", ")
              }], deadPlayers=[${incomingPayload.deadPlayers.join(", ")}]`,
            );
            this.callbackGameStateUpdate(
              incomingPayload.gameId,
              incomingPayload.playerOwnedTileUpdates,
              incomingPayload.deadPlayers,
            );
            return;
          }
          break;
        }

        case IncomingPayloadType.GameOver: {
          if (this.callbackGameOver !== null) {
            console.debug(
              `ApiClient: GameOver: gameId='${incomingPayload.gameId}', winner='${incomingPayload.winner}', winCondition='${incomingPayload.winCondition}'`,
            );
            this.callbackGameOver(incomingPayload.gameId, incomingPayload.winner, incomingPayload.winCondition);
            return;
          }
          break;
        }
      }

      console.error("ApiClient: unhandled IncomingPayload", incomingPayload);
    });
  }
}
